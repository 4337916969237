// ** Service
import EmployeeService from "../../services/EmployeeService";

const fetchAllEmployee = (dataItem) =>
  new Promise((resolve) => {
    EmployeeService.getAllEmployee(dataItem)
      .then((responseJson) => {
        resolve(responseJson.data.ResultOnDb);
      })
      .catch((error) => console.log(error));
  });

const fetchAllEmployeeByLike = (employeeCode = "") =>
  new Promise((resolve) => {
    const param = {
      EMPLOYEE_CODE: `${employeeCode}`,
    };
    EmployeeService.getByLikeEmployeeCode(param)
      .then((responseJson) => {
        resolve(responseJson.data.ResultOnDb);
      })
      .catch((error) => console.log(error));
  });

const fetchAllEmployeeByLikeWithoutDeptFitel = (employeeCode = "") =>
  new Promise((resolve) => {
    const param = {
      EMPLOYEE_CODE: `${employeeCode}`,
    };
    EmployeeService.getByLikeEmployeeCodeWithoutDeptFitel(param)
      .then((responseJson) => {
        resolve(responseJson.data.ResultOnDb);
      })
      .catch((error) => console.log(error));
  });

const fetchAllEmployeeByDept = (employeeCode = "", deptName = "") =>
  new Promise((resolve) => {
    const param = {
      EMPLOYEE_CODE: `${employeeCode}`,
      DEPT_NAME: `${deptName}`,
    };
    EmployeeService.getByLikeEmployeeCodeByDept(param)
      .then((responseJson) => {
        resolve(responseJson.data.ResultOnDb);
      })
      .catch((error) => console.log(error));
  });

const fetchAllEmployeeBySection = (employeeCode = "", sectionName = "") =>
  new Promise((resolve) => {
    const param = {
      EMPLOYEE_CODE: `${employeeCode}`,
      SECT_NAME: `${sectionName}`,
    };
    EmployeeService.getByLikeEmployeeCodeBySection(param)
      .then((responseJson) => {
        resolve(responseJson.data.ResultOnDb);
      })
      .catch((error) => console.log(error));
  });

const fetchAllEmployeeByDeptAndSection = (employeeCode = "", deptName = "", sectionName = "") =>
  new Promise((resolve) => {
    const param = {
      EMPLOYEE_CODE: `${employeeCode}`,
      DEPT_NAME: `${deptName}`,
      SECT_NAME: `${sectionName}`,
    };
    EmployeeService.getByLikeEmployeeCodeByDeptAndSection(param)
      .then((responseJson) => {
        resolve(responseJson.data.ResultOnDb);
      })
      .catch((error) => console.log(error));
  });

const fetchEmployeeFullName = (fullName = "") =>
  new Promise((resolve) => {
    const param = {
      FULL_NAME: `${fullName}`,
    };
    EmployeeService.getEmployeeFullName(param)
      .then((responseJson) => {
        resolve(responseJson.data.ResultOnDb);
      })
      .catch((error) => console.log(error));
  });

const fetchEmployeeFullNameByDept = (fullName = "", deptName = "") =>
  new Promise((resolve) => {
    const param = {
      FULL_NAME: `${fullName}`,
      DEPT_NAME: `${deptName}`,
    };
    EmployeeService.getEmployeeFullNameByDept(param)
      .then((responseJson) => {
        resolve(responseJson.data.ResultOnDb);
      })
      .catch((error) => console.log(error));
  });

const fetchEmployeeFullNameBySection = (fullName = "", sectionName = "") =>
  new Promise((resolve) => {
    const param = {
      FULL_NAME: `${fullName}`,
      SECT_NAME: `${sectionName}`,
    };
    EmployeeService.getEmployeeFullNameBySection(param)
      .then((responseJson) => {
        resolve(responseJson.data.ResultOnDb);
      })
      .catch((error) => console.log(error));
  });

const fetchEmployeeFullNameByDeptAndSection = (fullName = "", deptName = "", sectionName = "") =>
  new Promise((resolve) => {
    const param = {
      FULL_NAME: `${fullName}`,
      DEPT_NAME: `${deptName}`,
      SECT_NAME: `${sectionName}`,
    };
    EmployeeService.getEmployeeFullNameByDeptAndSection(param)
      .then((responseJson) => {
        resolve(responseJson.data.ResultOnDb);
      })
      .catch((error) => console.log(error));
  });

export {
  fetchAllEmployee,
  fetchAllEmployeeByLike,
  fetchEmployeeFullName,
  fetchAllEmployeeByLikeWithoutDeptFitel,
  fetchAllEmployeeByDept,
  fetchAllEmployeeBySection,
  fetchAllEmployeeByDeptAndSection,
  fetchEmployeeFullNameByDept,
  fetchEmployeeFullNameBySection,
  fetchEmployeeFullNameByDeptAndSection,
};
