// ** Icons Import
import {
  Briefcase,
  CheckSquare,
  Edit,
  Edit2,
  Edit3,
  File,
  FilePlus,
  FileText,
  Monitor,
  Search,
  Settings,
  User,
} from "react-feather";

export default [
  {
    header: "Leave System Menu",
    // meta: {
    //   publicMenuGroup: false
    // }
  },
  {
    id: "204",
    title: "Leave Request",
    icon: <FilePlus size={12} />,
    navLink: "/leave-request",
    //meta: {
    //   publicRoute: false,
    //   publicGroup: false
    //   }
  },
  {
    id: "205",
    title: "Leave History",
    icon: <Search size={12} />,
    navLink: "/leave-history",
    //meta: {
    //   publicRoute: false,
    //   publicGroup: false
    //   }
  },

  {
    id: "206",
    title: "Leave Document",
    icon: <FileText size={12} />,
    navLink: "/leave-regularity",
    //meta: {
    //   publicRoute: false,
    //   publicGroup: false
    //   }
  },
  {
    id: "444",
    title: "Employee Leave",
    icon: <FileText size={12} />,
    navLink: "/employee-leave",
    //meta: {
    //   publicRoute: false,
    //   publicGroup: false
    //   }
  },
  {
    id: "207",
    title: "Leave Approval",
    icon: <CheckSquare size={12} />,
    navLink: "/approval",
  },

  {
    id: "208",
    title: "AL Remain",
    icon: <Monitor size={12} />,
    navLink: "/remain-al",
  },
  {
    id: "268",
    title: "Remain Leave",
    icon: <Briefcase size={12} />,
    navLink: "/remain-leave",
  },
  {
    id: "209",
    title: "Check Subordinate Leave",
    icon: <Monitor size={12} />,
    navLink: "/sub-leave",
  },
  {
    id: "210",
    title: "Check Employee Leave",
    icon: <Monitor size={12} />,
    navLink: "/hr-check",
  },
  {
    id: "417",
    title: "Check Employee Leave M75",
    icon: <Monitor size={12} />,
    navLink: "/hr-check-m75",
  },
  {
    id: "249",
    title: "HR Create",
    icon: <File size={12} />,
    children: [
      {
        id: "250",
        title: "Leave Request Form",
        icon: <FilePlus size={16} />,
        navLink: "/leave-request-form-hr",
      },
      {
        id: "251",
        title: "M.75 Form",
        icon: <FilePlus size={16} />,
        navLink: "/m-75-form-hr",
      },
      {
        id: "273",
        title: "Import Excel Form",
        icon: <FilePlus size={16} />,
        navLink: "/excel-form",
      },
    ],
  },
  {
    id: "211",
    title: "HR Setting",
    icon: <Settings size={12} />,
    children: [
      {
        id: "212",
        title: "Document",
        icon: <Edit size={16} />,
        navLink: "/leave-regularity-setting",
      },
      {
        id: "213",
        title: "Leave Type",
        icon: <Edit3 size={12} />,
        navLink: "/leave-type-setting",
      },
      {
        id: "274",
        title: "Leave Type Regulation",
        icon: <Edit3 size={12} />,
        navLink: "/leave-type-regulation",
      },
    ],
  },
  {
    id: "214",
    title: "HR Edit",
    icon: <Edit2 size={12} />,
    children: [
      {
        id: "215",
        title: "User Leave",
        icon: <User size={12} />,
        navLink: "/user-leave-edit",
      },
      {
        id: "248",
        title: "User probation",
        icon: <User size={12} />,
        navLink: "/user-edit",
      },
    ],
  },
];
