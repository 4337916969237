// ** Service
import EmployeeService from "../../services/EmployeeService";

const fetchSection = (e) =>
  new Promise((resolve) => {
    EmployeeService.getSection(e || "")
      .then((responseJson) => {
        // console.log(responseJson.data.ResultOnDb);
        resolve(responseJson.data.ResultOnDb);
      })
      .catch((error) => console.log(error));
  });

export default fetchSection;
